.actionBtnCellrendrer {
    color: #0072c6;

    svg {
        margin-right: 16px;
        cursor: pointer;
    }

    i {
        cursor: pointer;
    }
}
.exportButton {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
}
.exportButton:hover {
    opacity: 0.7;
}

.ag-cell-focus,
.ag-cell {
    border: none;
}

.spaceSpan {
    text-align: center;
}

.ag-theme-alpine .ag-icon-menu:before,
.ag-theme-alpine .ag-icon-asc:before,
.ag-theme-alpine .ag-icon-desc:before,
.ag-theme-alpine .ag-icon-filter:before {
    color: #888685;
}
.ag-body-viewport,
.ag-paging-panel,
.ag-row-even {
    background: #f5f5f5 !important;
}

.custom-tooltip {
    background-color: white;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 6px 14px 0px, rgba(0, 0, 0, 0.11) 0px 2px 4px 0px;
    pointer-events: none;
    transition: opacity 0.3s;
    padding: 10px;

    div {
        white-space: nowrap;
    }

    .bold {
        font-weight: bold;
    }

    .tooltip-header {
        white-space: nowrap;
        font-weight: bold;
        font-size: larger;
        flex: 100%;
        padding-bottom: 10px;
    }

    .tooltip-container {
        display: flex;
        flex-wrap: nowrap;
        flex: 100%;
    }

    .tooltip-left {
        flex: 70%;
        padding-right: 15px;
    }

    .tooltip-right {
        flex: 30%;
        border-left: 1px solid rgb(212, 212, 212);
        padding-left: 15px;
        td:last-child {
            padding-left: 10px;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    td {
        white-space: nowrap;
        padding: 2px 0;
    }

    td:first-child {
        padding-right: 10px;
    }
}

.ag-full-width-row .ag-cell-wrapper.ag-row-group {
    font-weight: bold;
}
