@import './variable';

.layout-main-component {
    width: $fullWidth;
    order: 2;
    transition: width 0.3s ease-in-out;
}

.componentContainer {
    background-color: #ffffff;
    padding: 30px 30px 0px 30px;
}

.layoutContainer {
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    margin: 15px 45px 0px 45px;

    .AuthFailure {
        margin: auto;
        padding-top: 15%;
        text-align: center;
    }

    .center {
        text-align: center;
        align-content: center;
        align-self: center;
    }

    .breadCrumbs {
        padding-left: $containerStandardPadding;
        background-color: $off-white-light;
        z-index: 1;
    }
}

.spinner-custom-style {
    display: flex;
    height: inherit;
    justify-content: center;
}

.logoSibarContainer {
    width: $logoContainerSidebar;
}

.pageContentArea {
    margin-top: 65px;
    width: $contentContainerWidth;
}
.logoContainerBox {
    width: 120px;
    padding: 0 0 0 18px;
}
