.changeLogTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    td,
    th {
        padding: 3px;
        vertical-align: top;
    }
    td:first-child {
        width: 14%;
    }
    td:not(:first-child) {
        width: 43%;
    }
    .versionRow {
        font-size: 16px;
        font-weight: bold;
        td {
            padding-top: 10px;
        }
    }
}
