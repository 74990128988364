@import './styles/variable';
@import './styles/header';
@import './styles/layoutComponent';

body,
html {
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
select,
label {
    font-size: 0.8rem !important;
}

textarea {
    line-height: 1.2rem !important;
    font-size: 12px !important;
}
.container {
    padding-left: 8px;
    display: flex;
}
.fullWidthNoPadding {
    width: 100%;
}
.infoContainer {
    padding: 10px 20px;
    width: 99.5%;
}
.modalContainerMaxWidth {
    padding: 12px;
}
.noPadnoMar {
    margin: 0;
    padding: 0;
}
.modalContainerhollow {
    padding: 10px 20px;
    margin-top: 10rem;
    width: 75rem;
    height: 25rem;
    text-align: center;
}
.modalContainerBigSize {
    padding: 10px 10px;
    width: 70rem;
    height: 30rem;
}

.modalContainerBigWidth {
    width: 70vw;
    max-width: 70rem;
}

.modalContainer {
    padding: 10px;
    margin: 10px;
    font-size: medium;
}

.modalContainerContentScroll {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modalSmallContainerContentScroll {
    height: auto;
    min-height: 10vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modalContainerContentScroll::-webkit-scrollbar {
    -webkit-appearance: none;
    overflow: auto;
}

.modalContainerContentScroll::-webkit-scrollbar:vertical {
    width: 12px;
}
.modalContainerContentScroll::-webkit-scrollbar:horizontal {
    height: 12px;
}

.modalContainerContentScroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.modalContainerContentScroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.infoCard {
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
}
.defaultPadding {
    padding: 0.5rem;
}
.rowPadding {
    padding: 0.5rem 0rem;
    display: flex;
}
.rowPaddingEsowHeader {
    padding: 0.5rem 0 0 0.5rem;
    display: flex;
}
.noPadding {
    padding: 0 !important;
}
.nopt {
    font-size: 12px;
    font-weight: 600;
}
.mt {
    margin: 10px 0px;
}
.mt5 {
    margin: 5px 0px;
}

.ml {
    margin-left: 15px;
}
.lightFont {
    color: #605e5c;
    font-weight: 400;
}
.customTextArea {
    width: 100%;
    text-align: left;
    white-space: normal;
    padding: 7px 5px;
    border: 1px solid #000000;
}
.selectedColor {
    color: #7089ac;
}

.cursorpointer {
    cursor: pointer;
}

.no-underline {
    text-decoration: none;
}

.dashedHint {
    padding: 0px 5px;
    margin-left: 15px;
    border: 2px dashed darkgrey;
}

.cursorPointer {
    cursor: pointer;
}

.spaceSpan {
    padding: 0.5px;
}

.ag-theme-alpine .ag-ltr .ag-header-select-all {
    margin-right: 10px !important;
}

@keyframes loader {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}

@-webkit-keyframes loader {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}
.command_bar_style {
    background-color: white;
    padding: 4px 0px;
    padding-right: 10px;
    position: sticky;
    top: 0px;
    z-index: 1;
}
.pageHeaderStyle {
    color: '#fff';
    font-size: '26px';
    font-weight: 'bold';
}

//thumbnails
.thumbnail-file-type {
    display: flex;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 16px;
    min-height: 7rem;
}

.disabled-thumbnail-tile {
    background-color: lightgray;
    cursor: not-allowed;
}

.disabled-thumbnail-tile-404 {
    cursor: not-allowed;
    opacity: 0.5;
    z-index: 99;
}

.disabled-thumbnail-tile-404 > .thumbnail-icon-description::after {
    content: ' (Coming Soon)';
    font-size: 14px;
    font-weight: 500;
}

.disabled-thumbnail-tile-401 {
    cursor: not-allowed;
    opacity: 0.8;
    z-index: 99;
}

.disabled-thumbnail-tile-401 > .thumbnail-icon-description::before {
    content: 'Access Required';
    font-size: 14px;
    font-weight: 500;
}

.thumbnail-icon-style {
    font-size: 3rem;
    padding: 15px 15px 2px 15px;
    color: darkgray;
    // background-color: #7089AC;
    background-color: #f8f8f8;
    border: 2px dashed darkgray;
    width: 300px;
    display: flex;
    align-items: center; // Vertical centering
    justify-content: center;
}

.thumbnail-icon-style-disabled {
    font-size: 3rem;
    padding: 10px 25px 2px 25px;
    color: white;
    background-color: darkgrey;
}

.thumbnail-icon-style-disabled-404 {
    font-size: 3rem;
    padding: 10px 25px 2px 25px;
    color: white;
    background-color: darkgrey;
    opacity: 0.3;
}

.thumbnail-icon-description {
    font-size: 1rem;
    padding: 5px 10px;
    font-weight: 500;
    // box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    width: -webkit-fill-available;

    h2 {
        margin-top: 0;
        font-size: medium;
    }

    a {
        text-decoration: none;
        color: #7089ac;

        &:hover {
            color: #000000;
        }
    }
}

.filetableHeaderCols {
    td {
        color: #7089ac;
        font-size: 12px;
    }
}
.filetableListCols {
    td {
        color: #605e5c;
        line-height: normal;
        font-size: 12px;
    }
}
//status
.statusIconContainer {
    height: 35px;
}
.diamond {
    width: 25px;
    height: 25px;
    margin: 3px;
    background-image: url('./assets/status_red.svg');
    margin: 3px;
}

.triangle-up {
    width: 25px;
    height: 25px;
    margin: 3px;
    background-image: url('./assets/status_yellow.svg');
    margin: 3px;
}

.circle {
    width: 25px;
    height: 25px;
    margin: 3px;
    background-image: url('./assets/status_green.svg');
}
.circle-white {
    width: 25px;
    height: 25px;
    margin: 3px;
    background-image: url('./assets/status_na.svg');
}

// DRAFT EDITOR
.draft-editor {
    width: '-webkit-fill-available';
}

.editor-class {
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid rgb(96, 94, 92);
    min-height: 5rem;
}

.toolbar-class .rdw-option-disabled {
    display: none;
}

.toolbar-class p {
    margin-left: 1rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: rgb(32, 31, 30);
}

.rdw-editor-toolbar {
    border: none !important;
    padding-left: 0 !important;
}

.rdw-option-wrapper {
    margin-left: 0 !important;
    border: 1px solid rgb(96, 94, 92) !important;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.rdw-link-modal {
    left: 0px !important;
    top: 40px !important;
}

.rdw-link-modal-target-option {
    display: none !important;
}

.rdw-link-modal-input {
    border: 1px solid rgb(96, 94, 92) !important;
}

.command_bar_style {
    background-color: white;
    padding: 4px 0px;
    padding-right: 10px;
    position: sticky;
    top: 0px;
    z-index: 1;
    justify-content: baseline;
}

// Filter by milestone
.filterByMilestone {
    margin-bottom: 10px !important;
    padding-left: 8px;
}

.asteriskIcon {
    color: red;
    font-size: 6px;
    position: relative;
    top: -10px;
}

.errorMessage {
    color: rgb(164, 38, 44);
    font-size: 12px;
    font-weight: 400;
}

.textfield-high {
    height: 15rem;
}
.textfield-high-rich {
    height: 5rem;
}
.sct-notification {
    position: fixed;
    width: 65%;
    top: 0;
    left: calc(13% - 5px);
    z-index: 9999999;
}
.paragraphGap {
    margin-bottom: 50px;
    margin-top: 30px;
    border-bottom: 1px solid rgb(204, 204, 204);
    box-shadow: 0px 0.6px 1.8px #fff, 0px 3.2px 7.2px #fff;
}
.paragraphGapEditMode {
    background-color: #ffffff;
    width: '100%';
    padding: 0px 10px 10px 10px;
    box-shadow: 0px 0.6px 1.8px #ccc, 0px 3.2px 7.2px #ccc;
}
.paragraphGapNormalView {
    margin-bottom: 30px;
    margin-top: 30px;
    box-shadow: 0px 0.6px 1.8px #fff, 0px 3.2px 7.2px #fff;
}
.dropdown {
    display: block;
    background-color: #f9f9f9;
    min-width: 185px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 5px 5px 5px 3px;
    z-index: 1;
    margin-left: -160px;
    margin-top: auto;
    list-style-type: none;
}

.dropdown li {
    margin: 10px;
    cursor: pointer;
}

.ag-theme-alpine-compact {
    --ag-font-size: 12px !important;
}

.ag-theme-alpine-compact-medium {
    --ag-grid-size: 4px !important;
    --ag-list-item-height: 20px !important;
}

.ag-row-group {
    background-color: #f3f3f3;
    font-size: 16px;
}

.ag-row-odd {
    background-color: rgba(255, 255, 255, 0) !important;
}

.asr-header-centered * {
    justify-content: center !important;
}

.truncate {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-section {
    display: flex;

    > span {
        padding-right: 14px;
        padding-bottom: 14px;
        width: 90%;
    }
}

.container-input-section {
    display: flex;

    > div {
        width: calc(calc(100% - 8px) / 3);
        padding-right: 14px;
    }
}

.esowButton {
    padding: 6px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 2px;
}

.exportButton {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
}
.exportButton:hover {
    opacity: 0.7;
}

.rdw-editor-main {
    border-radius: 5px;
}

.paddingRight {
    margin-right: 5%;
    margin-left: 5%;
}
.css-111 {
    justify-content: center;
}
.aboutContainer {
    padding: 0 20px 20px 20px;
    div {
        padding: 3px 0;
    }
}

.blackBar {
    position: fixed;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    left: 0;
    top: 69px;
    height: 37px;
    width: 100%;
    z-index: 20;
    background-color: black;
    color: white;
    @media (max-width: 1133px) {
        top: 90px;
    }
    @media (max-width: 1084px) {
        top: 100px;
    }
    .blackBarLink {
        color: white;
        text-decoration: none;
    }
    .blackBarLink:hover {
        text-decoration: underline;
    }
}

.esowMainContent {
    position: relative;
    top: 0;
    @media (max-width: 1133px) {
        top: 21px;
    }
    @media (max-width: 1084px) {
        top: 31px;
    }
}

.rulesWFDHeadingMainCss {
    position: sticky;
    top: 106px;
    z-index: 20;
    @media (max-width: 1133px) {
        top: 127px;
    }
    @media (max-width: 1084px) {
        top: 137px;
    }
}

.page-break {
    page-break-before: always;
}

.no-break {
    page-break-inside: avoid;
}
