$blue: #7089ac;
$off-white-light: #faf9f8;
$off-white: #efefef;
$black: #000;
$off-white: #f3f3f3;
$white: #ffffff;
$MidGrey: #cccccc;

$small: 500px;
$medium: 800px;
$mid-medium: 1000px;
$large: 1200px;

$containerExtremePadding: 20px;
$containerMediumPadding: 10px;
$containerStandardPadding: 8px;
$pt-5: 5px;

$fullWidth: 100%;
$logoContainerSidebar: 150px;
$contentContainerWidth: 100%;

@mixin divSize($qty, $padding) {
    width: ((100% - (($qty) * $padding)) / $qty);
}
