.pageBannerTop {
    width: 100%;
    position: fixed;
    z-index: 99998;
}
.headerSection {
    width: 100%;
    background-color: $off-white-light;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    z-index: 99999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
    max-height: 5rem;
    font-size: large;
    .appLogo {
        img {
            display: inline;
            padding-left: 6px;
            padding-top: 6px;
        }
    }

    .appIconAndName {
        display: inline-flex;
        padding: 4px 0 0 0;
        h4 {
            padding: 10px 6px;
            margin: 0;
            display: block;
            font-weight: bold;
            font-size: large;
        }
    }

    .appIconAndName img {
        padding: 5px 0 0 5px;
        height: 40px;
    }

    .headerNavList {
        right: 0;
        font-size: medium;
        i {
            padding: 9px 12px;
        }
        img {
            border-radius: 50%;
        }
    }
}

.searchValidationErrorMsg {
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0px;
    padding-top: 5px;
}

.navBarItems {
    background-color: #faf9f8;
    font-size: medium;
    line-height: 2em;
}

.navBarItems i {
    font-size: larger;
}

.navBarItems :hover {
    background-color: #faf9f8;
    text-decoration: 2px underline;
    text-underline-offset: 4px;
}

.navBarItemActive {
    background-color: $off-white-light;
    text-decoration: 2px underline;
    text-underline-offset: 4px;
}

.loginBadgeContainer {
    margin-right: 10px;

    .loginBadge {
        display: flex;
        padding: 3px 2px 0 5px;
        align-items: center;
        font-size: medium;

        div:first-child {
            padding-right: 10px;
        }

        .loginCaret {
            font-size: 10px;
            color: gray;
        }
    }

    .loginFlyoutCommands {
        display: flex;
        .loginFlyoutButtons {
            font-size: 12px;
            border-radius: 5px;
            height: 28px;
            border-color: rgb(184, 184, 184);
            margin-left: 10px;
        }
    }

    .loginFlyout {
        .loginFlyoutUser {
            div {
                margin-left: -20px;
                margin-right: -20px;
                padding: 5px 20px;
            }
        }
        .loginFlyoutInfo {
            div {
                margin-left: -20px;
                margin-right: -20px;
                padding: 5px 20px;
            }
        }
    }
}
